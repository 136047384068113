import React from 'react'
import H6 from 'src/components/Typography/H6'
import Subtitle from 'src/components/Typography/Subtitle'
import Wrapper from './style'

interface Props {
  id: number
  modalWindowOpen: Function
  title: string
  subtitle: string
  poster: string
}

const ExpositionSeries = (props: Props) => {
  const { id, title, subtitle, poster, modalWindowOpen } = props

  return (
    <Wrapper>
      <div className="poster image-zoom" onClick={() => modalWindowOpen(id)}>
        <img src={poster} alt={title} />
      </div>
      <div className="title">
        <H6 dangerouslySetInnerHTML={{ __html: title }} />
      </div>
      <div className="subtitle">
        <Subtitle dangerouslySetInnerHTML={{ __html: subtitle }} />
      </div>
    </Wrapper>
  )
}

export default ExpositionSeries
