import React from 'react'
import StyledH6 from './style'

interface Props {
  className?: string
  children?: React.ReactNode
  dangerouslySetInnerHTML?: any
}

const H6:React.FC<Props> = props => {
  const { className, children, ...other } = props

  return (
    <StyledH6 className={className} {...other}>
      {children ?? null}
    </StyledH6>
  )
}

export default H6
