import React from 'react'
import { Grid, Row, Col } from '@baevra/grid'
import H2 from 'src/components/Typography/H2'
import H5 from 'src/components/Typography/H5'
import Subtitle from 'src/components/Typography/Subtitle'
import Quote from 'src/components/Typography/Quote'
import Wrapper from './style'

interface Props {
  about: string
  quote: {
    text: string
    author: {
      name: string
      about: string
      avatar: string
    }
  }
}

const ExpositionAbout: React.FC<Props> = props => {
  const { about, quote } = props

  return (
    <Wrapper id="about">
      <Grid xsColumnGap={0} lgColumnGap={20}>
        <Row xsMarginBottom={40} lgMarginBottom={80}>
          <Col xs={12} lg={2} lgOffset={1}>
            <H2>О проекте</H2>
          </Col>
        </Row>
        <Row xsMarginBottom={0}>
          <Col xs={12} lg={5} lgOffset={1} className="grid-column quote">
            <Quote>{quote.text}</Quote>
            <div className="author">
              <div className="photo">
                <img src={quote.author.avatar} alt="Avatar"/>
              </div>
              <div className="about">
                <Subtitle>{quote.author.name}</Subtitle>
                <Subtitle>{quote.author.about}</Subtitle>
              </div>
            </div>
          </Col>
          <Col xs={12} lg={4}>
            <H5 dangerouslySetInnerHTML={{ __html: about }} />
          </Col>
        </Row>
      </Grid>
    </Wrapper>
  )
}

export default ExpositionAbout
