import React from 'react'
import { Link } from 'gatsby'
import { Grid, Row, Col } from '@baevra/grid'
import H1 from 'src/components/Typography/H1'
import Caption from 'src/components/Typography/Caption'
import NavLink from 'src/components/NavLink'
import Wrapper from './style'
import { join } from 'upath'

interface Props {
  pathname: string
}

const ExpositionBreadcrumbs: React.FC<Props> = props => {
  const { pathname } = props

  return (
    <Wrapper>
      <div className="mobile">
        <NavLink to={join(pathname, '/archives')}>Архив</NavLink>
        <span className="divider" />
        <NavLink to={pathname}>О проекте</NavLink>
        <span className="divider" />
        <NavLink to={join(pathname, '/announcements')}>Анонсы</NavLink>
      </div>
      <div className="desktop">
        <Grid xsColumnGap={0}>
          <Row xsMarginBottom={0}>
            <Col xs={12} lg={4}>
              <H1>
                <Link to={join(pathname, '/archives')}>Архив</Link>
              </H1>
              <Caption>Прошедшие выставки с фотоотчетами и комментариями</Caption>
            </Col>
            <Col xs={12} lg={4}></Col>
            <Col xs={12} lg={4}>
              <H1>
                <Link to={join(pathname, '/announcements')}>Анонсы</Link>
              </H1>
              <Caption>Текущие и предстоящие выставки</Caption>
            </Col>
          </Row>
        </Grid>
      </div>
    </Wrapper>
  )
}

export default ExpositionBreadcrumbs
