import React from 'react'
import { Grid, Row, Col } from '@baevra/grid'
import H2 from 'src/components/Typography/H2'
import H5 from 'src/components/Typography/H5'
import Caption from 'src/components/Typography/Caption'
import Wrapper from './style'

interface Props {
  title: string
  subtitle: string
  caption: string
  poster: string
}

const ExpositionCaption: React.FC<Props> = props => {
  const { title, subtitle, caption, poster } = props

  return (
    <Wrapper>
      <Grid xsColumnGap={0} lgColumnGap={20}>
        <Row xsMarginBottom={0}>
          <Col xs={12} lg={4} lgOffset={4}>
            <div className="subtitle">
              <H5 italic dangerouslySetInnerHTML={{ __html: subtitle }} />
            </div>
            <div className="title">
              <H2 dangerouslySetInnerHTML={{ __html: title }} />
            </div>
            <div className="caption">
              <Caption dangerouslySetInnerHTML={{ __html: caption }} />
            </div>
          </Col>
        </Row>
        <Row xsMarginBottom={0}>
          <Col xs={12} lg={4} lgOffset={4}>
            <div className="poster">
              <img src={poster} alt={title} />
            </div>
          </Col>
        </Row>
      </Grid>
    </Wrapper>
  )
}

export default ExpositionCaption
