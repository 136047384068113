import React, { useState } from 'react'
import Layout from 'src/layout'
import ProgressBar from 'src/components/ProgressBar'
import Navbar from 'src/sections/Navbar'
import ExpositionHeader from 'src/sections/ExpositionHeader'
import ExpositionAbout from 'src/sections/ExpositionAbout'
import ExpositionVideo from 'src/sections/ExpositionVideo'
import ExpostionContacts from 'src/sections/ExpositionContacts'
import ExpositionSeries from 'src/sections/ExpositionSeries'
import ModalWindow from 'src/components/ModalWindow'
import Carousel from 'src/components/Carousel'
import ExpositionBreadcrumbs from 'src/sections/ExpositionBreadcrumbs'
// import { exposition as exposition_mock } from 'src/data/storage/localStorage/exposition'
import removeHTMLTags from 'src/utils/removeHTMLTags'
import SEO from 'src/components/SEO'

const createExposition = (exposition: any) => {
  const title = removeHTMLTags(exposition.title)
  const subtitle = removeHTMLTags(exposition.acf.authors)
  const caption = removeHTMLTags(exposition.acf.about)
  const about = removeHTMLTags(exposition.acf.project_short_description)

  const image = {
    src: exposition.better_featured_image?.source_url,
    width: exposition.better_featured_image?.media_details.width,
    height: exposition.better_featured_image?.media_details.height
  }
  const poster = exposition.acf.poster.source_url

  let videoGroup:any = []

  if (exposition.acf.video_group) {
    exposition.acf.video_group.forEach((video:any) => {
      video && video.url && videoGroup.push({
        src: video.url.url,
        caption: video.url.title
      })
    })
  }

  const video = exposition.acf.presentation_video && {
    src: exposition.acf.presentation_video,
    caption: null
  }

  // const video = exposition.acf.presentation_video

  const quote = {
    text: exposition.acf.text,
    author: {
      name: exposition.acf.author.title,
      about: exposition.acf.author.acf.info,
      avatar: exposition.acf.author.better_featured_image?.source_url
    }
  }
  const series = {
    about: exposition.acf.about_series,
    list: exposition.acf.series.map((series: any) => ({
      title: series.title,
      subtitle: series.acf.description,
      count: series.acf.exhibits.length,
      poster: series.better_featured_image?.source_url,
      images: series.acf.exhibits.map((exhibit: any) => ({
        src: exhibit.source_url,
        caption: removeHTMLTags(exhibit.description)
      }))
    }))
  }

  return {
    title,
    subtitle,
    caption,
    image,
    poster,
    about,
    video,
    videoGroup,
    quote,
    series
  }
}


const ExpositionPage: React.FC = (props: any) => {
  const exposition = createExposition(props.pageContext.exposition)

  let videoContent = []

  if (exposition.videoGroup && exposition.videoGroup.length > 0) {
    videoContent = exposition.videoGroup
  } else if (exposition.video) {
    videoContent.push(exposition.video)
  }

  const [modalWindowIsOpen, setModalWindowOpen] = useState(false)
  const [activeSeries, setActiveSeries] = useState(exposition.series.list[0].images)

  const handleModalWindowOpen = (id:number) => {
    setActiveSeries(exposition.series.list[id].images)
    setModalWindowOpen(true)
  }

  const handleCloseModalWindow = () => {
    setModalWindowOpen(false)
  }

  return (
    <Layout>
      <SEO
        title={exposition.title}
        description={exposition.about}
        url={props.location.href}
        ogImage={exposition.image}
      />
      <ProgressBar />
      <main>
        <div className="desktop">
          <Navbar />
        </div>
        <ExpositionBreadcrumbs pathname={props.location.pathname}/>
        <ExpositionHeader
          title={exposition.title}
          subtitle={exposition.subtitle}
          caption={exposition.caption}
          poster={exposition.poster}
        />
        <ExpositionAbout
          about={exposition.about}
          quote={exposition.quote}
        />
        <ExpositionSeries series={exposition.series} modalWindowOpen={handleModalWindowOpen}/>
        {/* { !exposition.video ? null
          //@ts-ignore-next-line
          : <ExpositionVideo src={exposition.video} />
        } */}
        {videoContent.length > 0 && (
          videoContent.map((video:any, index:number) => {
            if (video.src) {
              return (
                <ExpositionVideo
                  withTitle={index === 0}
                  key={video.caption}
                  src={video.src}
                  caption={video.caption}
                />
              )
            } else {
              return null
            }
          })
          )
        }
        <ExpostionContacts />
        <ModalWindow isOpen={modalWindowIsOpen} onClose={handleCloseModalWindow}>
          <Carousel images={activeSeries} />
        </ModalWindow>
      </main>
      <div className="bar" style={{ zIndex: -1 }} />
    </Layout>
  )
}


export default ExpositionPage
