import styled from 'styled-components'
import { up } from 'src/utils/media'

const Wrapper = styled.section`
  display: flex;
  flex-flow: column;
  text-align: center;
  align-items: center;
  padding-top: 3rem;
  margin-bottom: 7rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: ${props => props.theme.containerWidth.sm};

  h5 {
    white-space: pre-line;
  }

  & .grid-column {
    display: flex;
    flex-flow: column;
  }

  & h2 {
    text-transform: uppercase;
  }

  .author {
    margin-top: 4rem;
    margin-bottom: 5rem;
    padding: 0 1rem;

    & > .photo {
      display: flex;
      flex-shrink: 0;
      width: 5.6rem;
      height: 5.6rem;
      border-radius: 50%;
      margin-bottom: 2.5rem;
      margin-left: auto;
      margin-right: auto;
      overflow: hidden;

      & > img {
        width: inherit;
        height: inherit;
        object-fit: cover!important;
      }
    }

    & > .about {
      width: 70%;
      text-align: center;
      margin: 0 auto;
    }
  }

  .quote + .grid-column {
    text-align: left;
  }

  ${up('tablet')} {
    max-width: ${props => props.theme.containerWidth.lg};
    padding-top: 7rem;
    margin-bottom: 10rem;
    text-align: left;
    align-items: flex-start;

    .quote {
      padding-left: 5rem;
      padding-right: 8rem;
    }

    .author {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      padding: 0 0 0 8rem;

      & > .photo {
        margin: 0 2.4rem 0 0;
      }

      & > .about {
        width: 100%;
        text-align: left;
        margin: 0;
      }
    }
  }

  ${up('desktop')} {
    padding-top: 12rem;
    margin-bottom: 12rem;

    .quote {
      padding-left: 7rem;
      padding-right: 12rem;
    }
  }

  ${up('largeDesktop')} {
    padding-top: 25rem;
    margin-bottom: 25rem;
    
    .grid-row:nth-child(1) {
      margin-bottom: 15rem!important;
    }
  }
`

export default Wrapper
