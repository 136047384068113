import styled from 'styled-components'
import { up } from 'src/utils/media'

const StyledH6 = styled.h6`
  font-family: ${props => props.theme.fontFamily.primary};
  color: ${props => props.theme.colors.bodyTextDark};
  font-size: 1.7rem;
  line-height: 1.36;
  font-weight: 500;

  ${up('tablet')} {
    font-size: 1.7rem;
  }

  ${up('desktop')} {
    font-size: 1.9rem;
  }

  ${up('largeDesktop')} {
    font-size: 4rem;
  }
`

export default StyledH6
