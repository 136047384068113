import styled from 'styled-components'
import { up, down } from 'src/utils/media'

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: ${props => props.theme.containerWidth.sm};
  margin-bottom: 6rem;
  text-align: center;

  ${down('desktop')} {
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }

  & > .poster {
    display: flex;
    width: 100%;
    padding-top: 100%;
    margin-bottom: 3rem;
    overflow: hidden;
    position: relative;
    cursor: pointer;

    & > img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: inherit;
      height: inherit;
      object-fit: cover;
      min-height: 100%;
      min-width: 100%;
      transform: scale(1) translate(-50%,-50%);
      transition: transform 1s ease-out;
      transform-origin: top left;
    }

    &:hover {
      & > img {
        transform: scale(1.05) translate(-50%,-50%);
      }
    }

  }

  & > .title {
    width: 100%;
    margin-bottom: 2rem;
    overflow: hidden;
  }

  & > .subtitle {
    width: 100%;
    padding: 0 3rem;
    overflow: hidden;
  }

  ${up('mobile')} {
    width: calc((100% - 5rem)/2);
    margin-right: 5rem;
    margin-bottom: 5rem;

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  ${up('tablet')} {
    width: calc((100% - 6.5rem * 3)/4);
    margin-right: 6.5rem;
    margin-bottom: 8rem;

    &:nth-child(2n) {
      margin-right: 6.5rem;
    }

    &:nth-child(4n) {
      margin-right: 0;
    }

    & > .title {
      height: 5rem;
    }

    & > .subtitle {
      height: 4em;
      padding: 0;
      overflow: visible;
    }
  }

  ${up('desktop')} {
    width: calc((100% - 4.5vw * 3)/4);
    margin-right: 4.5vw;
    margin-bottom: 10rem;

    & > .poster {
      margin-bottom: 2.5rem;
    }

    & > .subtitle {
      padding: 0 3rem;
    }
  }

  ${up('largeDesktop')} {
    width: calc((100% - 30rem * 3)/4);
    margin-right: 30rem;
  }

`

export default Wrapper
