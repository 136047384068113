import styled from 'styled-components'
import { up } from 'src/utils/media'

const Wrapper = styled.section`
  width: 100%;

  ${up('tablet')} {
    display: flex;
    width: inherit;
    position: absolute;
    overflow-x: hidden;
    top: 60vh;
    height: 20rem;
    justify-content: space-between;
    z-index: 0;

    ${up('desktop')} {
      height: 35rem;
    }

    ${up('largeDesktop')} {
      height: 50rem;
    }
  }

  & > .mobile {
    display: flex;
    width: ${props => props.theme.containerWidth.sm};
    margin: 4rem auto 1rem auto;
    justify-content: center;

    a {
      color: ${props => props.theme.colors.button};
      font-size: 1.7rem;
      line-height: 2;
    }

    span.divider {
      width: 2rem;
      height: 1px;
      background-color: ${props => props.theme.colors.button};
      margin: auto 1.5rem;

      ${up('mobile')} {
        margin: auto 2rem;
      }
    }
  }

  & > .desktop {
    width: 100%;

    & .grid-row {
      padding: 0;
    }

    & .grid-column {
      position: relative;

      & > h1 {
        width: 100%;
        text-align: center;
        /* cursor: pointer; */
        position: absolute;
        text-transform: uppercase;
        left: 50%;
        transform: translateX(-50%);
        padding-left: .95em;

        & > a {
          text-decoration: none;
          outline: none;
          font-family: inherit;
          font-size: inherit;
          color: inherit;
        }

        &::after {
          content:'';
          position: absolute;
          width: 10rem;
          height: 2px;
          top: 50%;
          background-color: ${props => props.theme.colors.separator[300]};
          transform: translateY(-50%);
          transition: all .7s ease-out;
          z-index: -1;

          ${up('desktop')} {
            width: 13rem;
          }

          ${up('largeDesktop')} {
            width: 26rem;
          }

        }

        &:hover {
          letter-spacing: 1.15em;
          margin-right: -1.15em;

          &::after {
            width: 50%;
          }
        }

        & + p {
          text-align: center;
          position: absolute;
          top: 6rem;
          left: 50%;
          transform: translateX(-50%);

          ${up('desktop')} {
            top: 8rem;
          }
          ${up('largeDesktop')} {
            top: 17rem;
          }
        }

      }

      &:nth-child(1) {
        & > h1::after {
          left: 0;
        }
      }

      &:nth-last-child(1) {
        & > h1 ::after {
          right: 0;
        }
      }
    }
  }

`

export default Wrapper
