import React from 'react'
import { Grid, Row, Col } from '@baevra/grid'
import H2 from 'src/components/Typography/H2'
import H5 from 'src/components/Typography/H5'
import Series from 'src/components/ExpositionsSeriesItem'
import Wrapper from './style'

interface SeriesListProps {
  title: string
  subtitle: string
  poster: string
  images: any
}

interface Props {
  modalWindowOpen: Function
  series: {
    about: string
    list: Array<SeriesListProps>
  }
}

const ExpositionSeries: React.FC<Props> = props => {
  const { modalWindowOpen, series } = props
  const { about, list } = series

  return (
    <Wrapper>
      <Grid xsColumnGap={0} lgColumnGap={20}>
        <Row xsMarginBottom={45} lgMarginBottom={80}>
          <Col xs={12} lg={4} lgOffset={1}>
            <H2>СОСТАВ ЭКСПОЗИЦИИ</H2>
          </Col>
        </Row>
        <Row xsMarginBottom={45} lgMarginBottom={80}>
          <Col xs={12} lg={4} lgOffset={2} className="grid-column about">
            <div>
              <H5>{about}</H5>
            </div>
          </Col>
        </Row>
        <Row xsMarginBottom={40} lgMarginBottom={80}>
          <Col xs={12} lg={10} lgOffset={1}>
            <div className="wrapper">
              {list.map((item, index) => (
                <Series
                  key={index}
                  id={index}
                  title={item.title}
                  subtitle={item.subtitle}
                  poster={item.poster}
                  modalWindowOpen={modalWindowOpen}
                />
              ))}
            </div>
          </Col>
        </Row>
      </Grid>
    </Wrapper>
  )
}

export default ExpositionSeries
